import { Box, Button, Grid, Typography } from "@mui/material";
import pcsPlanFormModel from "../../../common/pcsPlanFormModel";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { PcsPdpStatus } from "../../../../../app/models/staticData/pcsPdpStatus";
import { useAppDispatch, useAppSelector } from "../../../../../app/store/configureStore";
import LoadingComponent from "../../../../../app/layout/LoadingComponent";
import { searchReviewerByPersonIdAsync, cancelPlanAsync } from "../../../../../app/slices/pcsPdpSlice";
import AppLabelField from "../../../../../app/components/AppLabelField";
import { useFormContext } from "react-hook-form";
import { PdpStatusEnum } from "../../../models/pdpStatusEnum";
import { ViewReviewer } from "./ViewReviewer";
import { toast } from "react-toastify";
import { API_REQUEST_FULLFILLED, MESSAGE_PLAN_CANCELED, MESSAGE_PROCESSING_DETAILS } from "../../../../../app/utils/constant";
import { CancelPlanParameters } from "../../../models/cancelPlanParameters";
import { PlannedActivitiesHeader } from "../PlannedActivitiesHeader";
import { ViewFeedback } from "./ViewFeedback";
import { ApiRequestStatus } from "../../../../../app/enums/apiRequestStatus";
import { useMsal } from "@azure/msal-react";
import { UserClaims } from "../../../../../app/enums/userClaims";
import { PcsEnrolmentYear } from "../../../models/pcsEnrolmentYear";
import { useGetActivitiesAcrossDomains } from "../../../../../app/customHooks/useGetActivitiesAcrossDomains";
import { useGetCreditSummary } from "../../../../../app/customHooks/useGetCreditSummary";
import { useGetPcsEnrolmentYears } from "../../../../../app/customHooks/useGetPcsEnrolmentYears";
import { useReferenceData } from "../../../../../app/customHooks/useReferenceData";
import { getPcsEnrolmentYearsAsync } from "../../../../../app/slices/pcsEnrolmentSlice";
import { useGetPcsCurrentYear } from "../../../../../app/customHooks/useGetPcsCurrentYear";
import { AppPathEnum } from "../../../../../app/enums/appPathEnum";

export default function ViewPlannedActivities() {

    const methods = useFormContext();
    const { reset } = methods;

    const { External, WorkBasedLearning, PracticeReview } = pcsPlanFormModel.formField;

    const { enrolmentYearId } = useParams<{ enrolmentYearId: string }>();

    const { accounts } = useMsal();

    useEffect(() => {
        if (accounts.length > 0 && accounts[0]?.idTokenClaims) {
            setPersonId(accounts[0].idTokenClaims[UserClaims.EXTENSION_PORTALCRM_PERSONID] as string);
        }
    }, [accounts]);

    const [personId, setPersonId] = useState('');
    const [pcsPdpStatus, setPcsPdpStatus] = useState<PcsPdpStatus>();
    const [enrolmentYear, setEnrolmentYear] = useState<PcsEnrolmentYear | undefined>();

    const dispatch = useAppDispatch();

    const { workPostLevels, titles, pcsPdpStatuses, staticDataStateStatus } = useAppSelector(state => state.ReferenceData);
    const { reviewerDetails, activitiesAcrossDomains, planStateStatus } = useAppSelector(state => state.pcsPdp);
    const { enrolmentYears, enrolmentYearsStatus } = useAppSelector(state => state.pcsEnrolment);
    const { currentYear, currentYearStatus, summaryData, getCreditSummaryStatus } = useAppSelector(state => state.pcs);
    const { currentWorkDetails, currentWorkDetailsStatus } = useAppSelector(state => state.workpost);

    useReferenceData();
    useGetPcsEnrolmentYears();
    useGetPcsCurrentYear();
    useGetCreditSummary();
    useGetActivitiesAcrossDomains();

    useEffect(() => {
        if (enrolmentYears && enrolmentYears?.length > 0) {
            setEnrolmentYear(enrolmentYears?.find(x => x.enrolmentYearId === enrolmentYearId));
        }
    }, [enrolmentYears, enrolmentYearId]);

    useEffect(() => {
        if (pcsPdpStatuses && enrolmentYear) {
            setPcsPdpStatus(pcsPdpStatuses.find(x => x.id === enrolmentYear.pcsPdpReviewStatusId));
        }
    }, [pcsPdpStatuses, enrolmentYear]);

    useEffect(() => {
        if (enrolmentYear) {
            reset({
                [External.name]: enrolmentYear.pdpExternal,
                [WorkBasedLearning.name]: enrolmentYear.pdpWorkBasedLearning,
                [PracticeReview.name]: enrolmentYear.pdpPracticeReview,
            })
        }
    }, [External.name, PracticeReview.name, WorkBasedLearning.name, enrolmentYear, reset]);

    const canAddReviewDetails = () => {
        return isCurrentYearPlan() &&
            (pcsPdpStatus?.code === PdpStatusEnum[PdpStatusEnum.SD]
                || pcsPdpStatus?.code === PdpStatusEnum[PdpStatusEnum.RRI]
                || pcsPdpStatus?.code === PdpStatusEnum[PdpStatusEnum.RRC]
                || pcsPdpStatus?.code === PdpStatusEnum[PdpStatusEnum.RRD]);
    }

    const canViewReviewerDetails = () => {
        return (pcsPdpStatus?.code === PdpStatusEnum[PdpStatusEnum.RP]
            || pcsPdpStatus?.code === PdpStatusEnum[PdpStatusEnum.UR]
            || pcsPdpStatus?.code === PdpStatusEnum[PdpStatusEnum.RD]);
    }

    const canCancelReviewRequest = () => {
        return (isCurrentYearPlan() &&
            (pcsPdpStatus?.code === PdpStatusEnum[PdpStatusEnum.UR]
                || pcsPdpStatus?.code === PdpStatusEnum[PdpStatusEnum.RP]));
    }

    const isCurrentYearPlan = () => {
        return enrolmentYear?.year === currentYear;
    }

    const cancelPlan = () => {
        let cancelPlanObject: CancelPlanParameters = { enrolmentYearId: enrolmentYearId };
        dispatch(cancelPlanAsync(cancelPlanObject)).then((response: any) => {
            if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                dispatch(getPcsEnrolmentYearsAsync(personId)).then((response) => {
                    if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                        toast.success(MESSAGE_PLAN_CANCELED);
                    }
                });
            }
        });;
    }

    useEffect(() => {
        if (enrolmentYear !== null && reviewerDetails === null && enrolmentYear &&
            enrolmentYear?.pdpReviewerPersonId !== null && canViewReviewerDetails()) {
            dispatch(searchReviewerByPersonIdAsync(enrolmentYear?.pdpReviewerPersonId));
        }
    }, [enrolmentYear, reviewerDetails, pcsPdpStatus, dispatch]);

    if (staticDataStateStatus === ApiRequestStatus.Pending
        || planStateStatus === ApiRequestStatus.Pending
        || enrolmentYearsStatus === ApiRequestStatus.Pending
        || currentYearStatus === ApiRequestStatus.Pending
        || getCreditSummaryStatus === ApiRequestStatus.Pending
        || currentWorkDetailsStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <>
        <PlannedActivitiesHeader
            pcsPdpStatus={pcsPdpStatus} currentWorkDetails={currentWorkDetails}
            isCurrentYearPlan={isCurrentYearPlan()} workPostLevels={workPostLevels} summaryData={summaryData}
            activitiesAcrossDomains={activitiesAcrossDomains} currentYear={currentYear} />

        <Box sx={{ width: '100%' }}>
            <Grid container rowSpacing={4}>

                <Grid item xs={12}></Grid>

                <Grid item xs={12}>
                    <AppLabelField value={enrolmentYear?.pdpExternal ?? ''} label={External.label} />
                </Grid>

                <Grid item xs={12}>
                    <AppLabelField value={enrolmentYear?.pdpWorkBasedLearning ?? ''} label={WorkBasedLearning.label} />
                </Grid>

                <Grid item xs={12}>
                    <AppLabelField value={enrolmentYear?.pdpPracticeReview ?? ''} label={PracticeReview.label} />
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>

        </Box>

        {canViewReviewerDetails() &&
            <Box sx={{ width: '100%' }}>
                <Grid container rowSpacing={4}>

                    <Grid item xs={12}>
                        <Typography variant='h6'>Reviewer details</Typography>
                    </Grid>

                    <ViewReviewer title={titles?.find(x => x.id === reviewerDetails?.titleId)?.value}
                        reviewerDetails={reviewerDetails} />

                </Grid>
            </Box>
        }

        {enrolmentYear && pcsPdpStatus?.code === PdpStatusEnum[PdpStatusEnum.RD] === true &&
            <ViewFeedback enrolmentYearDetails={enrolmentYear} />
        }

        {isCurrentYearPlan() === true &&
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>

                <Grid container rowSpacing={4}>

                    <Grid item xs={12}></Grid>

                    {canAddReviewDetails() &&
                        <Grid item xs={9} sm={5} sx={{ display: 'flex', justifyContent: 'start' }}>
                            <Button variant="contained" color="primary"
                                component={Link} to={`${AppPathEnum.PCS_REVIEWER_DETAILS}/${enrolmentYearId}`}>
                                Add reviewer
                            </Button>
                        </Grid>
                    }

                    {canCancelReviewRequest() === true &&
                        <Grid item xs={4} sm={3} sx={{ display: 'flex', justifyContent: 'start' }}>
                            <Button variant="contained" color="primary" onClick={() => cancelPlan()}>
                                Cancel review request
                            </Button>
                        </Grid>
                    }

                    {pcsPdpStatus?.code === PdpStatusEnum[PdpStatusEnum.RD] === true &&
                        <Grid item xs={4} sm={3} sx={{ display: 'flex', justifyContent: 'start' }}>
                            <Button variant="contained" color="primary"
                                component={Link} to={`${AppPathEnum.PCS_PLAN_FEEDBACK_ENROLMENT}/${enrolmentYearId}`}>
                                Edit my comments
                            </Button>
                        </Grid>
                    }

                </Grid>
            </Box>
        }
    </>
}