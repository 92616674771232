import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../api/agent";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import { SummaryDto } from "../models/summary/summaryDto";
import { PCSSummaryDto } from "./pcsPdpSlice";
import { RegistrationDetailsDto } from "../models/pcsEnrolment/registrationDetailsDto";
import { RegistrationDto } from "../models/pcsEnrolment/registrationDto";
import { EducationalDetailsDto } from "../models/person/educationalDetailsDto";

interface PcsState {
    currentYear: number | null;
    summaryData: SummaryDto[] | null;
    pcsSummaryDto: PCSSummaryDto | null;
    registrationDetails: RegistrationDetailsDto | null;
    educationalDetails: EducationalDetailsDto | null,
    registration: RegistrationDto | null;

    registerStateStatus: ApiRequestStatus;
    saveRegistrationDetailsStatus: ApiRequestStatus;
    currentYearStatus: ApiRequestStatus;
    getCreditSummaryStatus: ApiRequestStatus;
}

const initialState: PcsState = {
    currentYear: null,
    summaryData: null,
    pcsSummaryDto: null,
    registrationDetails: null,
    educationalDetails: null,
    registration: null,
    registerStateStatus: ApiRequestStatus.Idle,
    saveRegistrationDetailsStatus: ApiRequestStatus.Idle,
    currentYearStatus: ApiRequestStatus.Idle,
    getCreditSummaryStatus: ApiRequestStatus.Idle
}

export const getRegistrationDetailsAsync = createAsyncThunk<RegistrationDto, string>(
    'pcsRegistrationDetails/getRegistrationDetailsAsync',
    async (personId, thunkAPI) => {
        try {
            return agent.Pcs.getRegistrationDetails(personId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const saveRegistrationDetailsAsync = createAsyncThunk(
    'pcsRegistrationDetails/saveRegistrationDetailsAsync',
    async ({registrationDetails, personId}: 
        {registrationDetails: RegistrationDetailsDto, personId: string}, thunkAPI) => {
        try {
            return agent.Pcs.submitRegistrationDetails(personId, registrationDetails);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getPcsCurrentYearAsync = createAsyncThunk<number>(
    'pcsSlice/getPcsCurrentYearAsync',
    async (_, thunkAPI) => {
        try {
            return agent.Pcs.getCurrentYearAsync();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getCreditSummaryAsync = createAsyncThunk<PCSSummaryDto, string>(
    'pcsSlice/getCreditSummaryAsync',
    async (personId, thunkAPI) => {
        try {
            return agent.Pcs.summaries(personId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const pcsSlice = createSlice({
    name: 'pcsSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(getRegistrationDetailsAsync.pending, (state) => {
            state.registerStateStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getRegistrationDetailsAsync.fulfilled, (state, action) => {
            state.registerStateStatus = ApiRequestStatus.Fulfilled;
            state.registrationDetails = action.payload;
            
            let educationalDetails: EducationalDetailsDto = {
                id: action.payload.id,
                qualificationCountryId: action.payload.qualificationCountryId,
                qualificationInstitutionId: action.payload.qualificationInstitutionId,
                graduationYear: action.payload.graduationYear?.toString(),
                otherQualifications: action.payload.otherQualifications,
                institutionAwardId: action.payload.institutionAwardId,
                personId: action.payload.personId
            }
            state.educationalDetails = educationalDetails;
            state.registration = action.payload;
        });
        builder.addCase(getRegistrationDetailsAsync.rejected, (state) => {
            state.registerStateStatus = ApiRequestStatus.Rejected;
        });
        
        builder.addCase(saveRegistrationDetailsAsync.pending, (state) => {
            state.saveRegistrationDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(saveRegistrationDetailsAsync.fulfilled, (state, action) => {
            state.registrationDetails = action.meta.arg.registrationDetails;
            state.saveRegistrationDetailsStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(saveRegistrationDetailsAsync.rejected, (state) => {
            state.saveRegistrationDetailsStatus = ApiRequestStatus.Rejected;
        });
        
        builder.addCase(getPcsCurrentYearAsync.pending, (state) => {
            state.currentYearStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getPcsCurrentYearAsync.fulfilled, (state, action) => {
            state.currentYear = action.payload;
            state.currentYearStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(getPcsCurrentYearAsync.rejected, (state) => {
            state.currentYearStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(getCreditSummaryAsync.pending, (state) => {
            state.getCreditSummaryStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getCreditSummaryAsync.fulfilled, (state, action) => {
            let summaryData: SummaryDto[] = [];
            if (action.payload.summaryData && action.payload.summaryData !== null && action.payload.summaryData.length > 0) {
                for (let j = 0; j < 8; j++) {
                    summaryData.push(action.payload.summaryData[1][j]);
                }
            }
            state.summaryData = summaryData;
            state.pcsSummaryDto = action.payload;
            state.getCreditSummaryStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(getCreditSummaryAsync.rejected, (state) => {
            state.getCreditSummaryStatus = ApiRequestStatus.Rejected;
        });

    })
})
