import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../api/agent";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import { PcsEnrolmentYear } from "../../features/pcsPlan/models/pcsEnrolmentYear";

interface PcsEnrolmentState {
    enrolmentYears: PcsEnrolmentYear[] | null;
    enrolmentYearsStatus: ApiRequestStatus;
}

const initialState: PcsEnrolmentState = {
    enrolmentYears: null,
    enrolmentYearsStatus: ApiRequestStatus.Idle
}

export const getPcsEnrolmentYearsAsync = createAsyncThunk<PcsEnrolmentYear[], string>(
    'pcsEnrolment/getPcsEnrolmentYearsAsync',
    async (personId, thunkAPI) => {
        try {
            return agent.PcsEnrolment.getPcsEnrolmentYearsAsync(personId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const pcsEnrolmentSlice = createSlice({
    name: 'pcsEnrolmentSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(getPcsEnrolmentYearsAsync.pending, (state) => {
            state.enrolmentYearsStatus = ApiRequestStatus.Pending;
        });

        builder.addCase(getPcsEnrolmentYearsAsync.fulfilled, (state, action) => {
            state.enrolmentYears = action.payload;
            state.enrolmentYearsStatus = ApiRequestStatus.Fulfilled;
        });

        builder.addCase(getPcsEnrolmentYearsAsync.rejected, (state) => {
            state.enrolmentYearsStatus = ApiRequestStatus.Rejected;
        });
    })
})
