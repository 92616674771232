import { Box, Grid, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import registrationFormModel from '../../app/models/pcsEnrolment/registrationFormModel';
import AppTextInput from '../../app/components/AppTextInput';
import { useAppSelector } from '../../app/store/configureStore';
import AppAutocomplete from '../../app/components/AppAutocomplete';
import { MapCountriesToAutocompleeItems, MapInstitutionsToAutocompleeItems } from '../../app/utils/convertStaticDataToAutocompleteItems';
import { useGetEducationalDetails } from '../../app/customHooks/useGetEducationalDetails';
import AppTextareaInput from '../../app/components/AppTextareaInput';
import { AutocompleteItem } from '../../app/models/common/autocompleteItem';

export default function EducationalDetails() {

  const methods = useFormContext();
  const { control, getValues, reset } = methods;

  const {
    personalDetailsFormField: {
      DOB
    },
    educationalDetailsFormField: {
      QualificationCountry,
      PrimaryMedicalDegreeCollege,
      OtherPrimaryMedicalDegreeCollege,
      QualificationYear,
      OtherQualifications
    } } = registrationFormModel;

  const { countries, institutionAwards, educationInstitutions } = useAppSelector(state => state.ReferenceData);
  const { personalDetails } = useAppSelector(state => state.personalDetail);
  const { educationalDetails } = useAppSelector(state => state.pmd);

  useGetEducationalDetails();

  const getQualificationYears = (startYear: number) => {
    let lastYear: number = new Date().getFullYear();
    let years: AutocompleteItem[] = [];

    while (startYear <= lastYear) {
      const qualificationYear = {
        id: startYear.toString(),
        label: startYear.toString(),
      };
      years.push(qualificationYear);
      startYear++;
    }
    return years;
  }

  const [qualificationYears,] = useState<AutocompleteItem[] | null>(
    getQualificationYears((new Date(personalDetails?.dateOfBirth!).getFullYear() + 16)));

  useEffect(() => {
    if (personalDetails !== undefined && personalDetails !== null
      && educationalDetails !== undefined && educationalDetails !== null
      && countries && countries?.length > 0 && educationInstitutions && educationInstitutions?.length > 0
      && institutionAwards && institutionAwards?.length > 0 && qualificationYears && qualificationYears?.length > 0) {

      let institutionId = institutionAwards.find(x => x.id === educationalDetails?.institutionAwardId)?.educationInstitutionId;
      let countryId = educationInstitutions.find(x => x.id === institutionId)?.countryId;
      reset({
        [QualificationCountry.name]: countryId,
        [PrimaryMedicalDegreeCollege.name]: institutionId,
        [QualificationYear.name]: educationalDetails?.graduationYear?.toString() ?? '',
        [DOB.name]: personalDetails.dateOfBirth
      })
    }
  }, [personalDetails, educationalDetails, countries, educationInstitutions, institutionAwards, qualificationYears, reset]);

  return (
    <>
      <Box sx={{ minWidth: "100%" }} component={Paper}>
        <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

          <Grid item xs={12}>
            <AppAutocomplete name={QualificationCountry.name}
              label={QualificationCountry.label}
              items={MapCountriesToAutocompleeItems(countries ?? [])} />
          </Grid>

          {getValues([OtherPrimaryMedicalDegreeCollege.name]).toString() === '' &&
            <Grid item xs={12}>
              <AppAutocomplete name={PrimaryMedicalDegreeCollege.name}
                label={PrimaryMedicalDegreeCollege.label}
                items={MapInstitutionsToAutocompleeItems(educationInstitutions ?? [])} />
            </Grid>
          }

          {getValues([PrimaryMedicalDegreeCollege.name]).toString() === '' &&
            <Grid item xs={12}>
              <AppTextareaInput name={OtherPrimaryMedicalDegreeCollege.name}
                label={OtherPrimaryMedicalDegreeCollege.label} />
            </Grid>
          }

          <Grid item xs={12}>
            <AppAutocomplete control={control}
              name={QualificationYear.name}
              label={QualificationYear.label}
              items={qualificationYears ?? []} />
          </Grid>

          <Grid item xs={12}>
            <AppTextInput control={control} multiline
              name={OtherQualifications.name}
              label={OtherQualifications.label} />
          </Grid>

          <Grid item xs={12}></Grid>

        </Grid>
      </Box>
    </>
  );
}
