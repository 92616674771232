import { AutocompleteItem } from "../models/common/autocompleteItem";
import { Country } from "../models/staticData/country";
import { Institution } from "../models/staticData/institution";

export function MapInstitutionsToAutocompleeItems(institutions: Institution[]) {
    let institutionList: AutocompleteItem[] = [];

    institutions?.map((item: Institution) => {
        institutionList.push({
            id: item.id,
            label: item.value
        });
        return true;
    });

    return institutionList;
}


export function MapCountriesToAutocompleeItems(countries: Country[]) {
    let countryList: AutocompleteItem[] = [];

    countries?.map((item: Country) => {
        countryList.push({
            id: item.id,
            label: item.value
        });
        return true;
    });

    return countryList;
}

export function yesNoObjectsToAutocompleteItem() {
    var yesNoObjects: AutocompleteItem[] = [];
    yesNoObjects.push({ id: "Yes", label: "Yes" });
    yesNoObjects.push({ id: "No", label: "No" });
    return yesNoObjects;
  }
  