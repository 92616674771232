import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store/configureStore';
import { ApiRequestStatus } from '../enums/apiRequestStatus';
import { useMsal } from '@azure/msal-react';
import { UserClaims } from '../enums/userClaims';
import { getWorkpostAsync } from '../slices/workpostSlice';

export const useGetWorkpost = () => {
    const { accounts } = useMsal();

    useEffect(() => {
        if (accounts.length > 0 && accounts[0]?.idTokenClaims) {
            setPersonId(accounts[0].idTokenClaims[UserClaims.EXTENSION_PORTALCRM_PERSONID] as string);
        }
    }, [accounts]);

    const [personId, setPersonId] = useState('');

    const dispatch = useAppDispatch();
    const { workpost, workpostStatus } = useAppSelector(state => state.workpost);

    useEffect(() => {
        if (!workpost && workpostStatus === ApiRequestStatus.Idle) {
            dispatch(getWorkpostAsync(personId));
          }
    }, [workpost, workpostStatus, dispatch]);

    return { workpostStatus };
};
