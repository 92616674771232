import { Box, Paper, Grid, Typography, Button } from "@mui/material";
import AppSelectList from "../../app/components/AppSelectList";
import { MapPaymentFrequencyToDropdownItems, MapTypeOfDirectDebitsToDropdownItems } from "../../app/utils/convertStaticDataToDropdownItems";
import { FormProvider, useForm } from "react-hook-form";
import AppTextInput from "../../app/components/AppTextInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { DonationDirectDebitValidationSchemas } from "../../app/models/directDebit/directDebitValidationSchemas";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import AppDierctDebitDeclarationCheckbox from "../../app/models/directDebit/AppDierctDebitDeclarationCheckbox";
import DirectDebitFormModel from "../../app/models/directDebit/directDebitFormModel";
import { DirectDebitTypeEnum } from "../../app/enums/directDebitTypeEnum";
import { getPersonalDetailsAsync } from "../../app/slices/personSlice";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import { UserClaims } from "../../app/enums/userClaims";
import { useMsal } from "@azure/msal-react";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { API_REQUEST_FULLFILLED, MESSAGE_DETAILS_SAVED, MESSAGE_PROCESSING_DETAILS } from "../../app/utils/constant";
import { toast } from "react-toastify";
import { submitDirectDebitDetailsAsync } from "../../app/slices/directDebitMandateSlice";
import SuccessResponseMessage from "../../app/components/SuccessResponseMessage";
import { PaymentFrequencyEnum } from "../../app/enums/paymentFrequencyEnum";
import { ApiRequestStatus } from "../../app/enums/apiRequestStatus";
import { useGetPersonalDetails } from "../../app/customHooks/useGetPersonalDetails";
import { CreateDirectDebitMandateCommand } from "../../app/models/directDebit/createDirectDebitMandateCommand";
import { useReferenceData } from "../../app/customHooks/useReferenceData";
import { DirectDebitCreditor } from "../../app/models/staticData/directDebitCreditor";
import { CreditorEnum } from "../../app/enums/creditorEnum";

export default function DonationsDirectDebit() {

    const {
        formField: {
            FirstName,
            Surname,
            FullName,
            BIC,
            IBAN,
            TypeOfDirectDebit,
            PaymentFrequency,
            Declaration
        }
    } = DirectDebitFormModel;

    const queryParams = new URLSearchParams(window.location.search);

    const history = useHistory();

    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(DonationDirectDebitValidationSchemas)
    });

    const { accounts } = useMsal();

    useEffect(() => {
        if (accounts.length > 0 && accounts[0]?.idTokenClaims) {
            setPersonId(accounts[0].idTokenClaims[UserClaims.EXTENSION_PORTALCRM_PERSONID] as string);
        }
    }, [accounts]);

    const [personId, setPersonId] = useState('');
    const [pageLoading, setPageLoading] = useState(false);
    const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);

    const dispatch = useAppDispatch();

    const { control, trigger, watch, reset, getValues, formState: { isDirty } } = methods;
    const formObj = methods.watch();
    const [typeOfDirectDebit, setTypeOfDirectDebit] = useState('');

    useReferenceData();

    const { directDebitCreditors, staticDataStateStatus } = useAppSelector(state => state.ReferenceData);
    const { personalDetails } = useAppSelector(state => state.personalDetail);

    const { personalDetailsStatus } = useGetPersonalDetails();

    const [directDebitCreditor, setDirectDebitCreditor] = useState<DirectDebitCreditor>();

    useEffect(() => {
        if (directDebitCreditors && directDebitCreditors?.length > 0) {
            setDirectDebitCreditor(directDebitCreditors.find(x => x.code === CreditorEnum.DevelopmentAndAlumniRelationsOffice));
        }
    }, [directDebitCreditors]);

    const MapToCreateDirectDebitMandateDto = () => {
        const paymentFrequency = formObj.PaymentFrequency === PaymentFrequencyEnum.Monthly ? "MONTH" :
            (formObj.PaymentFrequency === PaymentFrequencyEnum.Every3Months ? "MONTH" : "YEAR");
        const intervalCount = formObj.PaymentFrequency === PaymentFrequencyEnum.Every3Months ? 3 : 1;

        const data: CreateDirectDebitMandateCommand = {
            personId: personId,
            firstName: formObj.FirstName,
            surname: formObj.Surname,
            IBAN: formObj.IBAN,
            BIC: formObj.BIC,
            ShoppingBasketId: queryParams.get("basketId"),
            Recurring: formObj.TypeOfDirectDebit.toLowerCase() === DirectDebitTypeEnum.RECURRENT.toLowerCase() ? true : false,
            IntervalUnitCode: paymentFrequency,
            IntervalCount: intervalCount
        }
        return data;
    }

    const payDirectDebit = async () => {

        const isStepValid = await trigger();

        if (isStepValid) {
            if (isDirty === true) {
                setPageLoading(true);
                dispatch(submitDirectDebitDetailsAsync(MapToCreateDirectDebitMandateDto())).then((response: any) => {
                    if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                        dispatch(getPersonalDetailsAsync(personId)).then(() => {
                            toast.success(MESSAGE_DETAILS_SAVED);
                            setPageLoading(false);
                            setIsPaymentSuccessful(true);
                        });
                    }
                    else {
                        setPageLoading(false);
                    }
                });
            }
        }
    }

    useEffect(() => {
        watch((_, { name, type }) => {
            if (name === TypeOfDirectDebit.name && type === 'change') {
                setTypeOfDirectDebit(getValues(TypeOfDirectDebit.name));
            }
        });
    }, [TypeOfDirectDebit.name, watch]);

    useEffect(() => {
        reset({
            [FirstName.name]: personalDetails?.firstName,
            [Surname.name]: personalDetails?.lastName,
            [Declaration.name]: []
        })
    }, [personalDetails, reset]);

    if (isPaymentSuccessful === true) {
        return <SuccessResponseMessage header={'Thank you'}
            body={`You have successfully configured direct debit mandate.`} />
    }

    if (pageLoading === true || personalDetailsStatus === ApiRequestStatus.Pending ||
        staticDataStateStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <FormProvider {...methods}>
        <Box sx={{ width: '100%' }} component={Paper}>

            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <Grid item xs={12}>
                    <Typography variant='h4'>Direct debit</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant='body1'>
                        {directDebitCreditor?.value},<br></br>
                        RCSI (Royal College of Surgeons in Ireland),<br></br>
                        123 St Stephen's Green,<br></br>
                        Dublin2, Ireland<br></br>
                        Tel: +353 1 402 2729<br></br>
                        Email: {directDebitCreditor?.email}<br></br>
                        Creditor Identifier Number: {directDebitCreditor?.identifierNumber}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <AppTextInput control={control} name={FirstName.name} label={FirstName.label} />
                </Grid>

                <Grid item xs={12}>
                    <AppTextInput control={control} name={Surname.name} label={Surname.label} />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="subtitle1">
                        {FullName.information_text}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <AppTextInput control={control} name={BIC.name} label={BIC.label} />
                </Grid>

                <Grid item xs={12}>
                    <AppTextInput control={control} name={IBAN.name} label={IBAN.label} />
                </Grid>

                <Grid item xs={12}>
                    <AppSelectList
                        control={control}
                        name={TypeOfDirectDebit.name}
                        label={TypeOfDirectDebit.label}
                        items={MapTypeOfDirectDebitsToDropdownItems()}
                    />
                </Grid>

                {typeOfDirectDebit === DirectDebitTypeEnum.RECURRENT &&
                    <Grid item xs={12}>
                        <AppSelectList
                            control={control}
                            name={PaymentFrequency.name}
                            label={PaymentFrequency.label}
                            items={MapPaymentFrequencyToDropdownItems()}
                        />
                    </Grid>
                }

                <Grid item xs={12}>
                    <AppDierctDebitDeclarationCheckbox
                        control={control}
                        name={Declaration.name}
                        option={{
                            id: Declaration.name,
                            value: Declaration.label
                        }} />
                </Grid>

                <Grid item xs={11}>
                    <Typography variant='body1'>
                        <b>Note</b> - As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with
                        your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Your rights
                        are explained in a statement that you can obtain from your bank.
                    </Typography>
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Grid container rowSpacing={4}>
                <Grid item xs={12}></Grid>

                <Grid item xs={8.5}></Grid>

                <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Button variant="contained" color="primary" onClick={() =>
                        history.push(`${String(new URLSearchParams(window.location.search).get("cancelUrl"))}${window.location.search}`)
                    }>
                        Cancel
                    </Button>
                </Grid>

                <Grid item xs={0.5}></Grid>

                <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Button variant="contained" color="primary" onClick={payDirectDebit}>
                        Submit
                    </Button>
                </Grid>

                <Grid item xs={1}></Grid>
            </Grid>
        </Box>
    </FormProvider>
}
