import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/configureStore';
import { ApiRequestStatus } from '../enums/apiRequestStatus';
import { getEducationAsync } from '../slices/pmdSlice';

export const useGetEducationalDetails = () => {
    const dispatch = useAppDispatch();
    const { educationalDetails, educationalDetailsStatus } = useAppSelector(state => state.pmd);

    useEffect(() => {
        if (educationalDetails === null && educationalDetailsStatus === ApiRequestStatus.Idle) {
            dispatch(getEducationAsync());
        }
    }, [educationalDetails, educationalDetailsStatus, dispatch]);

    return { educationalDetailsStatus };
};
