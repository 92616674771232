import { Box, Grid, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { practiceOfMedicineOptions } from '../../app/utils/util';
import AppTextInput from '../../app/components/AppTextInput';
import registrationFormModel from '../../app/models/pcsEnrolment/registrationFormModel';
import AppSelectList from '../../app/components/AppSelectList';
import AppCheckboxField from '../../app/components/AppCheckboxField';
import { CheckBoxItem } from '../../app/models/common/checkBoxItem';
import { DropdownItem } from '../../app/models/common/dropdownItem';
import { MapSpecialitiesToDropdownItems } from '../../app/utils/convertStaticDataToDropdownItems';
import { useAppSelector } from '../../app/store/configureStore';
import { SchemEnum } from '../../app/enums/schemEnum';

interface Prop {
  reenrol: boolean;
}

export default function RegistrationDetails({ reenrol }: Prop) {

  const methods = useFormContext();
  const { control, watch, reset, getValues, formState: { isDirty } } = methods;

  const { specialities, schemes, registrationTypes } = useAppSelector(state => state.ReferenceData);
  const { registrationDetails } = useAppSelector(state => state.pcs);

  const [filteredSpecialities, setFilteredSpecialities] = useState<DropdownItem[]>([]);

  const {
    registrationDetailsFormField: {
      IMCNumber,
      RegistrationType,
      Scheme,
      EngagedInPracticeOfMedicine,
      Speciality,
      SubSpeciality,
      UserConfirmation
    }
  } = registrationFormModel;

  const [userConfirmationOptions,] = useState<CheckBoxItem[]>([{
    id: UserConfirmation.name,
    value: UserConfirmation.label
  }]);

  useEffect(() => {
    if (registrationDetails && registrationDetails !== null) {
      filteredSpecialitiesByScheme(registrationDetails.schemeId);
    }
  }, [registrationDetails]);

  useEffect(() => {
    if (registrationDetails && registrationDetails !== null
      && schemes && schemes?.length > 0 && specialities && specialities?.length > 0
      && registrationTypes && registrationTypes?.length > 0
      && filteredSpecialities && filteredSpecialities?.length > 0
      && isDirty === false) {

      reset({
        [IMCNumber.name]: registrationDetails?.imcNumber === null ? '' : registrationDetails?.imcNumber,
        [RegistrationType.name]: registrationDetails?.registrationTypeId === null ? '' : registrationDetails?.registrationTypeId,
        [Scheme.name]: registrationDetails?.schemeId === null ? '' : registrationDetails?.schemeId,
        [EngagedInPracticeOfMedicine.name]:
          registrationDetails?.engagedInPracticeOfMedicine === true ? '1' : '0',
        [Speciality.name]: registrationDetails?.specialtyId === null ? '' : registrationDetails?.specialtyId,
        [SubSpeciality.name]: registrationDetails?.subSpeciality === null ? '' : registrationDetails?.subSpeciality,
        [UserConfirmation.name]: []
      });
    }
  }, [registrationDetails, schemes, specialities, filteredSpecialities, registrationTypes, reset]);

  useEffect(() => {
    watch((_, { name, type }) => {
      if (name === Scheme.name && type === 'change') {
        filteredSpecialitiesByScheme(getValues(Scheme.name));
      }
    });
  }, [Scheme.name, watch]);

  const filteredSpecialitiesByScheme = (schemeId: string) => {
    let specialitiesByScheme = specialities?.filter(x => x.schemeId === schemeId) ?? [];
    let specialityList: DropdownItem[] = MapSpecialitiesToDropdownItems(specialitiesByScheme);
    setFilteredSpecialities(specialityList);
  }

  return (
    <>
      <Box sx={{ minWidth: "100%" }} component={Paper}>
        <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

          <Grid item xs={12}>
            <AppTextInput control={control}
              name={IMCNumber.name}
              label={IMCNumber.label}
              disabled={reenrol === true} />
          </Grid>

          <Grid item xs={12}>
            <AppSelectList control={control}
              name={RegistrationType.name}
              label={RegistrationType.label}
              items={registrationTypes === null ? [] : registrationTypes}
              disabled={reenrol === true} />
          </Grid>

          <Grid item xs={12}>
            <AppSelectList control={control}
              name={Scheme.name}
              label={Scheme.label}
              items={schemes === null ? [] : schemes}
              disabled={reenrol === true} />
          </Grid>

          <Grid item xs={12}>
            <AppSelectList control={control}
              name={EngagedInPracticeOfMedicine.name}
              label={EngagedInPracticeOfMedicine.label}
              items={practiceOfMedicineOptions()} />
          </Grid>

          <Grid item xs={12}>
            <AppSelectList control={control}
              name={Speciality.name}
              label={Speciality.label}
              items={filteredSpecialities ? filteredSpecialities : []} />
          </Grid>

          {schemes !== null && schemes.find(x => x.id === getValues(Scheme.name))?.code === SchemEnum.SURGERY &&
            <>
              <Grid item xs={12}>
                <AppTextInput control={control}
                  name={SubSpeciality.name}
                  label={SubSpeciality.label} />
              </Grid>
            </>}

          <Grid item xs={12}>
            <AppCheckboxField
              control={control}
              name={UserConfirmation.name}
              label={UserConfirmation.label}
              options={userConfirmationOptions} />
          </Grid>

          <Grid item xs={12}></Grid>

        </Grid>
      </Box>
    </>
  );
}
