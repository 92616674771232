const registrationFormModel = {
  formId: 'RegistrationForm',
  registrationDetailsFormField: {
    IMCNumber: {
      name: 'IMCNumber',
      label: 'IMC number*',
      requiredErrorMsg: 'IMC number is required',
      typeError: 'IMC number is required with numeric value.'
    },
    RegistrationType: {
      name: 'RegistrationType',
      label: 'Registration type*',
      requiredErrorMsg: 'Registration type is required'
    },
    Scheme: {
      name: 'Scheme',
      label: 'Scheme*',
      requiredErrorMsg: 'Scheme is required'
    },
    EngagedInPracticeOfMedicine: {
      name: 'EngagedInPracticeOfMedicine',
      label: 'Engaged in practice of medicine*',
      requiredErrorMsg: 'Engaged in practice of medicine is required'
    },
    UserConfirmation: {
      name: 'UserConfirmation',
      label: 'I confirm that my engagement in practice of medicine status is correct. I wish to proceed with my application and will notifiy the PCS office of any changes to this status.*',
      requiredErrorMsg: 'User confirmation is required'
    },
    Speciality: {
      name: 'Speciality',
      label: 'Speciality*',
      requiredErrorMsg: 'Speciality is required'
    },
    SubSpeciality: {
      name: 'SubSpeciality',
      label: 'Sub speciality*'
    }
  },
  personalDetailsFormField: {
    Title: {
      name: 'Title',
      label: 'Title*',
      requiredErrorMsg: 'Title is required'
    },
    FirstName: {
      name: 'FirstName',
      label: 'First name*',
      requiredErrorMsg: 'First name is required'
    },
    LastName: {
      name: 'LastName',
      label: 'LastName*',
      requiredErrorMsg: 'Last name is required'
    },
    DOB: {
      name: 'DOB',
      label: 'DOB*',
      requiredErrorMsg: 'DOB is required',
      invalidErrorMsg: 'DOB is not valid',
      typeError: 'Invalid DOB.'
    },
    Gender: {
      name: 'Gender',
      label: 'Gender*',
      requiredErrorMsg: 'Gender is required'
    },
    Nationality: {
      name: 'Nationality',
      label: 'Nationality*',
      requiredErrorMsg: 'Nationality is required'
    },
    MobileNumber: {
      name: 'MobileNumber',
      label: 'Mobile number*',
      requiredErrorMsg: 'Mobile number is required',
      typeError: 'Mobile number is not valid'
    }
  },
  educationalDetailsFormField: {
    QualificationCountry: {
      name: 'QualificationCountry',
      label: 'Qualification country*',
      requiredErrorMsg: 'Qualification country is required'
    },
    PrimaryMedicalDegreeCollege: {
      name: 'PrimaryMedicalDegreeCollege',
      label: 'University of College (Primary Medical) Degree*',
      requiredErrorMsg: 'University of College (Primary Medical) Degree is required'
    },
    OtherPrimaryMedicalDegreeCollege: {
      name: 'Other PrimaryMedicalDegreeCollege',
      label: 'Other University of College (Primary Medical) Degree*',
      requiredErrorMsg: 'Other University of College (Primary Medical) Degree is required',
      maxStringLengthErrorMsg: 'Maximum 200 characters allowed'
    },
    QualificationYear: {
      name: 'QualificationYear',
      label: 'Qualification year*',
      requiredErrorMsg: 'Qualification year is required'
    },
    OtherQualifications: {
      name: 'OtherQualifications',
      label: 'Other qualifications'
    }
  },
  workDetailsFormField: {
    StartDate: {
      name: 'StartDate',
      label: 'Start date*',
      requiredErrorMsg: 'Start date is required'
    },
    IfEngagedInPracticeOfMedicine: {
      name: 'IfEngagedInPracticeOfMedicine',
      label: 'Post Level (if engaged in Practice of Medicine)*',
      requiredErrorMsg: 'Post Level (if engaged in Practice of Medicine) is required'
    },
    IfNotEngagedInPracticeOfMedicine: {
      name: 'IfNotEngagedInPracticeOfMedicine',
      label: 'Post Level (if not engaged in Practice of Medicine)'
    },
    IsPostInIreland: {
      name: 'IsPostInIreland',
      label: 'Is this post in Ireland?'
    },
    NameOfEmployer: {
      name: 'NameOfEmployer',
      label: 'Name of employer'
    },
    NameOfClinicalSupervisor: {
      name: 'NameOfClinicalSupervisor',
      label: 'Name of Clinical Supervisor (if applicable)'
    }
  },
  paymentDetailsFormField: {
    NameOnCard: {
      name: 'nameOnCard',
      label: 'Name on card',
      requiredErrorMsg: 'Name on card is required'
    },
    CardNumber: {
      name: 'cardNumber',
      label: 'Card number',
      requiredErrorMsg: 'Card number is required',
      invalidErrorMsg: 'Card number is not valid (e.g. 4111111111111)'
    },
    ExpiryDate: {
      name: 'expiryDate',
      label: 'Expiry date',
      requiredErrorMsg: 'Expiry date is required',
      invalidErrorMsg: 'Expiry date is not valid'
    },
    CVV: {
      name: 'CVV',
      label: 'CVV',
      requiredErrorMsg: 'CVV is required',
      digitLengthErrorMessage: 'Must be exactly 3 digits',
      typeError: 'CVV must be a numeric value.',
      invalidErrorMsg: 'CVV is invalid (e.g. 357)'
    },
    SaveCard: {
      name: 'saveCard',
      label: 'Remember credit card details for next time'
    }
  }
};

export default registrationFormModel;