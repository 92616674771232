import { Box, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { ApiRequestStatus } from '../../app/enums/apiRequestStatus';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { useAppSelector } from '../../app/store/configureStore';
import { postLevelIfEngagedInPracticeOfMedicine, postLevelIfNotEngagedInPracticeOfMedicine } from '../../app/utils/util';
import registrationFormModel from '../../app/models/pcsEnrolment/registrationFormModel';
import AppDatePicker from '../../app/components/AppDatePicker';
import { useFormContext } from 'react-hook-form';
import AppCheckboxField from '../../app/components/AppCheckboxField';
import AppSelectList from '../../app/components/AppSelectList';
import AppTextInput from '../../app/components/AppTextInput';
import { DropdownItem } from '../../app/models/common/dropdownItem';
import { MESSAGE_PROCESSING_DETAILS } from '../../app/utils/constant';
import { useGetWorkpost } from '../../app/customHooks/useGetWorkpost';
import { yesNoObjectsToAutocompleteItem } from '../../app/utils/convertStaticDataToAutocompleteItems';
import AppAutocomplete from '../../app/components/AppAutocomplete';

export default function WorkDetails() {

  const methods = useFormContext();
  const { control, getValues } = methods;

  const { workpost, workpostStatus, saveWorkpostStatus } = useAppSelector(state => state.workpost);

  const {
    workDetailsFormField: {
      StartDate,
      IfEngagedInPracticeOfMedicine,
      IfNotEngagedInPracticeOfMedicine,
      IsPostInIreland,
      NameOfClinicalSupervisor,
      NameOfEmployer
    }
  } = registrationFormModel;

  const [nameOfEmployers] = useState<DropdownItem[] | null>([]);

  useGetWorkpost();

  if (workpostStatus === ApiRequestStatus.Pending || saveWorkpostStatus === ApiRequestStatus.Pending) {
    return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Grid container rowSpacing={4}>

          <Grid item xs={12}></Grid>

          <Grid item xs={12}>
            <AppDatePicker control={control} name={StartDate.name} label={StartDate.label} />
          </Grid>

          <Grid item xs={12}>
            <Typography variant='subtitle1'>{IfEngagedInPracticeOfMedicine.label}</Typography>
          </Grid>

          <Grid item xs={12}>
            <AppCheckboxField control={control} name={IfEngagedInPracticeOfMedicine.name}
              label={IfEngagedInPracticeOfMedicine.label} options={postLevelIfEngagedInPracticeOfMedicine()} />
          </Grid>

          <Grid item xs={12}>
            <Typography variant='subtitle1'>{IfNotEngagedInPracticeOfMedicine.label}</Typography>
          </Grid>

          <Grid item xs={12}>
            <AppCheckboxField control={control} name={IfNotEngagedInPracticeOfMedicine.name}
              label={IfNotEngagedInPracticeOfMedicine.label} options={postLevelIfNotEngagedInPracticeOfMedicine()} />
          </Grid>

          {getValues(IfEngagedInPracticeOfMedicine.name) !== undefined && getValues(IfEngagedInPracticeOfMedicine.name).length > 0 &&
            <>
              <Grid item xs={12}>
                <AppAutocomplete control={control} name={IsPostInIreland.name}
                  label={IsPostInIreland.label} items={yesNoObjectsToAutocompleteItem()} />
              </Grid>

              {console.log(getValues(IsPostInIreland.name))}

              {getValues(IsPostInIreland.name).toString().toLowerCase() === "yes" &&
                <>
                  <Grid item xs={12}>
                    <AppSelectList control={control} name={NameOfEmployer.name}
                      label={NameOfEmployer.label} items={nameOfEmployers ?? []} />
                  </Grid>

                  <Grid item xs={12}>
                    <AppTextInput control={control} name={NameOfClinicalSupervisor.name}
                      label={NameOfClinicalSupervisor.label} />
                  </Grid>
                </>
              }
            </>
          }
        </Grid>
      </Box >
    </>
  );
}
