import { Box, Paper, Grid, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import AppDatePicker from "../../components/AppDatePicker";
import AppSelectList from "../../components/AppSelectList";
import AppTextInput from "../../components/AppTextInput";
import {
    MapTitlesToDropdownItems, MapGendersToDropdownItems, MapCountriesToDropdownItems,
    MapDisciplinesToDropdownItems, MapAreaOfPracticesToDropdownItems
} from "../../utils/convertStaticDataToDropdownItems";
import { minEnrolmentYear } from "../../utils/util";
import MembershipFormModel from "./membershipFormModel";
import { MESSAGE_NOT_A_VALUE } from "../../utils/constant";
import { useAppSelector } from "../../store/configureStore";

export default function MemberPersonalDetails() {
    const methods = useFormContext();
    const { control, setValue, reset, getValues, watch } = methods;

    const {
        formField: {
            Title,
            FirstName,
            MiddleName,
            Preferredname,
            Surname,
            DateOfBirth,
            Gender,
            CountryOfNationality,
            PreferredPhoneNumber,
            Discipline,
            AreaOfPracticeMoreDetails
        }
    } = MembershipFormModel;

    const { titles, genders, countries, disciplines, areasOfPractice } = useAppSelector(state => state.ReferenceData);
    const { personalDetails } = useAppSelector(state => state.personalDetail);

    useEffect(() => {
        if (personalDetails !== undefined && personalDetails !== null
            && titles && titles?.length > 0 && genders && genders?.length > 0
            && countries && countries?.length > 0 && disciplines && disciplines?.length > 0
            && areasOfPractice && areasOfPractice?.length > 0) {

            reset({
                [Title.name]: personalDetails.titleId === null
                    ? '' : personalDetails.titleId,
                [FirstName.name]: personalDetails.firstName,
                [MiddleName.name]: personalDetails.middleName,
                [Surname.name]: personalDetails.lastName,
                [Preferredname.name]: personalDetails.prefferedName,
                [DateOfBirth.name]: personalDetails.dateOfBirth,
                [Gender.name]: personalDetails.genderId === null
                    ? '' : personalDetails.genderId,
                [CountryOfNationality.name]: personalDetails.nationalityId === null
                    ? '' : personalDetails.nationalityId,
                [PreferredPhoneNumber.name]: personalDetails.mobile,
                [Discipline.name]: (personalDetails.disciplineId === null ||
                    personalDetails.disciplineId === undefined) ? '' : personalDetails.disciplineId,
                [AreaOfPracticeMoreDetails.name]: (personalDetails.areaOfPracticeId === null ||
                    personalDetails.areaOfPracticeId === undefined) ? '' : personalDetails.areaOfPracticeId
            })
        }
        if (!areasOfPractice || areasOfPractice?.length === 0) {
            setValue(AreaOfPracticeMoreDetails.name, MESSAGE_NOT_A_VALUE);
        }
    }, [personalDetails, titles, genders, countries, disciplines, areasOfPractice, reset]);

    useEffect(() => {
        watch((value, { name, type }) => {
            if (name === Discipline.name && type === 'change') {
                if (areasOfPractice?.filter(x => x.disciplineId === getValues(Discipline.name)) &&
                    areasOfPractice?.filter(x => x.disciplineId === getValues(Discipline.name)).length > 0) {
                    setValue(AreaOfPracticeMoreDetails.name, '');
                }
                else {
                    setValue(AreaOfPracticeMoreDetails.name, MESSAGE_NOT_A_VALUE);
                }
            }
        });
    }, [Discipline.name, watch]);

    return <>
        <Box sx={{ minWidth: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <Grid item xs={12} sx={{ marginTop: '-1%' }}>
                    <Typography variant='subtitle1' sx={{ fontWeight: "500" }}>Personal details</Typography>
                </Grid>

                <Grid item xs={12}>
                    <AppSelectList control={control} name={Title.name} label={Title.label}
                        items={MapTitlesToDropdownItems(titles ?? [])} />
                </Grid>

                <Grid item xs={12}>
                    <AppTextInput name={FirstName.name} label={FirstName.label} disabled={true} />
                </Grid>

                <Grid item xs={12}>
                    <AppTextInput name={MiddleName.name} label={MiddleName.label}
                        disabled={personalDetails?.middleName && personalDetails?.middleName !== null ? true : false} />
                </Grid>

                <Grid item xs={12}>
                    <AppTextInput name={Surname.name} label={Surname.label} disabled={true} />
                </Grid>

                <Grid item xs={12}>
                    <AppTextInput name={Preferredname.name} label={Preferredname.label} />
                </Grid>

                <Grid item xs={12}>
                    <AppDatePicker
                        control={control}
                        name={DateOfBirth.name}
                        label={DateOfBirth.label}
                        maxDate={minEnrolmentYear()}
                        disabled={personalDetails?.dateOfBirth && personalDetails?.dateOfBirth !== null ? true : false} />
                </Grid>

                <Grid item xs={12}>
                    <AppSelectList
                        control={control}
                        name={Gender.name}
                        label={Gender.label} items={MapGendersToDropdownItems(genders ?? [])} />
                </Grid>

                <Grid item xs={12}>
                    <AppSelectList
                        control={control}
                        name={CountryOfNationality.name}
                        label={CountryOfNationality.label}
                        items={MapCountriesToDropdownItems(countries ?? [])}
                        disabled={personalDetails?.nationalityId && personalDetails?.nationalityId !== null ? true : false}
                    />
                </Grid>

                <Grid item xs={12}>
                    <AppTextInput name={PreferredPhoneNumber.name} label={PreferredPhoneNumber.label} />
                </Grid>

                <Grid item xs={12}>
                    <AppSelectList
                        control={control}
                        name={Discipline.name}
                        label={Discipline.label}
                        items={MapDisciplinesToDropdownItems(disciplines ?? [])}
                    />
                </Grid>

                {areasOfPractice?.filter(x => x.disciplineId === getValues(Discipline.name)) &&
                    areasOfPractice?.filter(x => x.disciplineId === getValues(Discipline.name)).length > 0 &&
                    <Grid item xs={12}>
                        <AppSelectList
                            control={control}
                            name={AreaOfPracticeMoreDetails.name}
                            label={AreaOfPracticeMoreDetails.label}
                            items={MapAreaOfPracticesToDropdownItems(areasOfPractice?.filter(
                                x => x.disciplineId === getValues(Discipline.name)) ?? [])}
                        />
                    </Grid>
                }
                <Grid item xs={12}></Grid>

            </Grid>
        </Box>
    </>
}