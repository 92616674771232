import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store/configureStore';
import { ApiRequestStatus } from '../enums/apiRequestStatus';
import { useMsal } from '@azure/msal-react';
import { UserClaims } from '../enums/userClaims';
import { getRegistrationDetailsAsync } from '../slices/pcsSlice';

export const useGetRegistrationDetails = () => {
    const { accounts } = useMsal();

    useEffect(() => {
        if (accounts.length > 0 && accounts[0]?.idTokenClaims) {
            setPersonId(accounts[0].idTokenClaims[UserClaims.EXTENSION_PORTALCRM_PERSONID] as string);
        }
    }, [accounts]);

    const [personId, setPersonId] = useState('');

    const dispatch = useAppDispatch();
    const { registrationDetails, saveRegistrationDetailsStatus } = useAppSelector(state => state.pcs);

    useEffect(() => {
        if (personId !== "" && registrationDetails === null && saveRegistrationDetailsStatus === ApiRequestStatus.Idle) {
            dispatch(getRegistrationDetailsAsync(personId));
        }
    }, [personId, registrationDetails, saveRegistrationDetailsStatus, dispatch]);

    return { saveRegistrationDetailsStatus };
};
