import { Box, Button, Grid, Step, StepLabel, Stepper } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import PersonalDetails from './PersonalDetails';
import Payment from './Payment';
import { registrationValidationSchema } from '../../app/models/pcsEnrolment/registrationValidationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from '../../app/store/configureStore';
import { RegistrationDetailsDto } from '../../app/models/pcsEnrolment/registrationDetailsDto';
import {
  API_REQUEST_FULLFILLED, MESSAGE_DETAILS_SAVED, MESSAGE_SAVE_AND_CONTINUE,
  MESSAGE_NEXT, MESSAGE_SUBMIT, MESSAGE_BACK_TO_PREVIOUS_STEP, MESSAGE_PROCESSING_DETAILS
} from '../../app/utils/constant';
import { getPersonalDetailsAsync, updatePersonalDetailsStateAsync } from '../../app/slices/personSlice';
import { PersonalDetailsDto } from '../../app/models/person/personalDetailsDto';
import { ApiRequestStatus } from '../../app/enums/apiRequestStatus';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { getEducationAsync, saveEducationAsync, updateEducationAsync } from '../../app/slices/pmdSlice';
import { EducationalDetailsDto } from '../../app/models/person/educationalDetailsDto';
import { toast } from 'react-toastify';
import { useMsal } from '@azure/msal-react';
import { UserClaims } from '../../app/enums/userClaims';
import EducationalDetails from './EducationalDetails';
import RegistrationDetails from './RegistrationDetails';
import WorkDetails from './WorkDetails';
import Header from './Header';
import { useReferenceData } from '../../app/customHooks/useReferenceData';
import { useGetPersonalDetails } from '../../app/customHooks/useGetPersonalDetails';
import { useGetRegistrationDetails } from '../../app/customHooks/useGetRegistrationDetails';
import { useGetEducationalDetails } from '../../app/customHooks/useGetEducationalDetails';
import { PcsEnrolmentStepEnum } from '../../app/models/pcsEnrolment/pcsEnrolmentStepEnum';
import { WorkPostDto } from '../../app/models/workpost/workPostDto';
import { saveWorkpostAsync } from '../../app/slices/workpostSlice';
import { getRegistrationDetailsAsync, saveRegistrationDetailsAsync } from '../../app/slices/pcsSlice';
import { UpdateEducationCommand } from '../../app/models/person/updateEducationCommand';

interface Prop {
  reenrol: boolean;
}

export default function Enrolment({ reenrol }: Prop) {

  const steps = [PcsEnrolmentStepEnum.RegistrationDetails, PcsEnrolmentStepEnum.PersonalDetails,
  PcsEnrolmentStepEnum.EducationalDetails, PcsEnrolmentStepEnum.WorkDetails, PcsEnrolmentStepEnum.Payment];

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return <RegistrationDetails reenrol={reenrol} />
      case 1:
        return <PersonalDetails reenrol={reenrol} />
      case 2:
        return <EducationalDetails />
      case 3:
        return <WorkDetails />
      case 4:
        return <Payment />
      default:
        throw new Error('Unknown step');
    }
  }

  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = registrationValidationSchema[activeStep];
  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(currentValidationSchema)
  });
  const { trigger, formState: { isDirty } } = methods;
  const form = methods.watch();

  const { accounts } = useMsal();

  useEffect(() => {
    if (accounts.length > 0 && accounts[0]?.idTokenClaims) {
      setPersonId(accounts[0].idTokenClaims[UserClaims.EXTENSION_PORTALCRM_PERSONID] as string);
    }
  }, [accounts]);

  const [personId, setPersonId] = useState('');

  const dispatch = useAppDispatch();

  const { staticDataStateStatus } = useAppSelector(state => state.ReferenceData);
  const { registerStateStatus, saveRegistrationDetailsStatus } = useAppSelector(state => state.pcs);
  const { personalDetails, personalDetailsStatus, updatePersonalDetailsStatus } = useAppSelector(state => state.personalDetail);
  const { educationalDetails, educationalDetailsStatus, saveEducationalDetailsStatus, updateEducationalDetailsStatus } =
    useAppSelector(state => state.pmd);
  const { workpostStatus } = useAppSelector(state => state.workpost);

  useReferenceData();
  useGetRegistrationDetails();
  useGetPersonalDetails();
  useGetEducationalDetails();

  const handleNext = async () => {
    const isStepValid = await trigger();

    if (isStepValid) {
      if (isDirty === true) {
        switch (activeStep) {
          case 0:
            let registrationDetails = MapToRegistrationDetailsDto();
            dispatch(saveRegistrationDetailsAsync({ registrationDetails, personId })).then((response: any) => {
              if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                toast.success(MESSAGE_DETAILS_SAVED);
                dispatch(getRegistrationDetailsAsync(personId)).then((registrationDetailsResponse) => {
                  if (registrationDetailsResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                    setActiveStep(prevActiveStep => prevActiveStep + 1);
                  }
                });
              }
            });
            break;
          case 1:
            let personalDetailsDto = MapToPersonalDetailsDto();
            dispatch(updatePersonalDetailsStateAsync(personalDetailsDto)).then((response: any) => {
              if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                toast.success(MESSAGE_DETAILS_SAVED);
                dispatch(getPersonalDetailsAsync(personId)).then((personalDetailsResponse) => {
                  if (personalDetailsResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                    setActiveStep(prevActiveStep => prevActiveStep + 1);
                  }
                });
              }
            });
            break;
          case 2:
            dispatch(updateEducationAsync(MapToUpdateEducationCommand())).then((response: any) => {
              if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                toast.success(MESSAGE_DETAILS_SAVED);
                dispatch(getEducationAsync()).then((educationalDetailsResponse) => {
                  if (educationalDetailsResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                    setActiveStep(prevActiveStep => prevActiveStep + 1);
                  }
                });
              }
            });
            break;
          case 3:
            let workpostDto = MapToWorkDetailsDto();
            dispatch(saveWorkpostAsync(workpostDto)).then((response: any) => {
              if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                toast.success(MESSAGE_DETAILS_SAVED);
                setActiveStep(prevActiveStep => prevActiveStep + 1);
              }
            });
            break;
          default:
            return "not a valid step";
        }
      }
      else if (isDirty === false) {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      }
    }
  };

  async function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  function MapToRegistrationDetailsDto() {
    const data: RegistrationDetailsDto = {
      imcNumber: form.IMCNumber,
      registrationTypeId: form.RegistrationType,
      schemeId: form.Scheme,
      specialtyId: form.Speciality,
      engagedInPracticeOfMedicine: form.EngagedInPracticeOfMedicine === '1',
      subSpeciality: form.SubSpeciality,
      declaration: form.UserConfirmation.length > 0
    }
    return data;
  }

  function MapToPersonalDetailsDto() {
    const data: PersonalDetailsDto = {
      id: personalDetails?.id,
      titleId: form.Title,
      genderId: form.Gender,
      nationalityId: form.Nationality,
      firstName: form.FirstName,
      lastName: form.LastName,
      preferredEmail: personalDetails?.preferredEmail,
      dateOfBirth: form.DOB,
      mobile: form.MobileNumber
    }
    return data;
  }

  function MapToUpdateEducationCommand() {
    const data: UpdateEducationCommand = {
      qualificationCountryId: form.QualificationCountry,
      qualificationInstitutionId: form.PrimaryMedicalDegreeCollege,
      qualificationInstitutionOther: form.OtherPrimaryMedicalDegreeCollege,
      qualificationYear: form.QualificationYear,
      otherQualifications: form.OtherQualifications
    }
    return data;
  }

  function MapToWorkDetailsDto() {
    const data: WorkPostDto = {
      // engagedInPracticeOfMedicine: form.EngagedInPracticeOfMedicine,
      inIreland: form.IsPostInIreland,
      clinicalSupervisor: form.NameOfClinicalSupervisor,
      // notEngagedInPracticeOfMedicine: form.NotengagedInPracticeOfMedicine,
      startDate: form.StartDate,
      id: null,
      personId: personId,
      workPostLevelId: null,
      employerId: form.NameOfEmployer
    }
    return data;
  }

  const [width, setWidth] = useState<number>(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  if (staticDataStateStatus === ApiRequestStatus.Pending
    || registerStateStatus === ApiRequestStatus.Pending
    || personalDetailsStatus === ApiRequestStatus.Pending
    || educationalDetailsStatus === ApiRequestStatus.Pending
    || saveRegistrationDetailsStatus === ApiRequestStatus.Pending
    || updatePersonalDetailsStatus === ApiRequestStatus.Pending
    || saveEducationalDetailsStatus === ApiRequestStatus.Pending
    || updateEducationalDetailsStatus === ApiRequestStatus.Pending
    || workpostStatus === ApiRequestStatus.Pending) {
    return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
  }

  return (
    <FormProvider {...methods}>
      <Header />

      <br></br>

      <Stepper activeStep={activeStep} orientation={isMobile === true ? "vertical" : "horizontal"}>
        {steps.map((label) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <br></br>

      {activeStep !== 0 && (
        <Button variant="contained" onClick={_handleBack}>
          {MESSAGE_BACK_TO_PREVIOUS_STEP}
        </Button>
      )}

      {activeStep < steps.length &&
        <>
          {getStepContent(activeStep)}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Grid container rowSpacing={4}>
              <Grid item xs={12}></Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={8} sm={8} sx={{ display: 'flex', justifyContent: 'end' }}>
                <Button variant="contained" color="primary" onClick={handleNext}>
                  {activeStep === steps.length - 1 ? MESSAGE_SUBMIT : (isDirty === true ? MESSAGE_SAVE_AND_CONTINUE : MESSAGE_NEXT)}
                </Button>
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
          </Box>
        </>}
    </FormProvider >
  );
};
