import { Box, Paper, Grid, Typography } from "@mui/material";
import AppSelectList from "../../app/components/AppSelectList";
import { useFormContext } from "react-hook-form";
import AppTextareaInput from "../../app/components/AppTextareaInput";
import {
    MapFamAffiliateCategoriesToDropdownItems, MapFamAffiliatePathwaysToDropdownItems
} from "../../app/utils/convertStaticDataToDropdownItems";
import { useEffect, useState } from "react";
import AppDeclarationCheckbox from "./common/AppDeclarationCheckbox";
import { CheckBoxItem } from "../../app/models/common/checkBoxItem";
import MembershipFormModel from "../../app/models/membership/membershipFormModel";
import { useAppSelector } from "../../app/store/configureStore";

export default function Membership() {
    const { famAffiliateCategories, famAffiliatePathways } = useAppSelector(state => state.ReferenceData);
    const { affiliateOnboardingDetails } = useAppSelector(state => state.affiliateOnboardingDetails);

    const methods = useFormContext();
    const { control, getValues, reset } = methods;

    const {
        formField: {
            LevelOfStudyOrTraining,
            StatusAffiliateMemberPathway,
            AffiliateMemberPathwayComments,
            HaveNotSuccessfullyPassedMrcsPartBExam,
            ReasonToBecomeAffiliateMemberOfRCSI
        }
    } = MembershipFormModel;

    const [declarationOption,] = useState<CheckBoxItem>({
        id: HaveNotSuccessfullyPassedMrcsPartBExam.name,
        value: ''
    });

    useEffect(() => {
        reset({
            [LevelOfStudyOrTraining.name]: affiliateOnboardingDetails?.affiliateCategoryId ?? '',
            [StatusAffiliateMemberPathway.name]: affiliateOnboardingDetails?.affiliatePathwayId ?? '',
            [HaveNotSuccessfullyPassedMrcsPartBExam.name]: [],
            [AffiliateMemberPathwayComments.name]: affiliateOnboardingDetails?.pathwayComment ?? '',
            [ReasonToBecomeAffiliateMemberOfRCSI.name]: affiliateOnboardingDetails?.reasons ?? ''
        })
    }, []);

    return <>
        <Box sx={{ minWidth: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <Grid item xs={12} sx={{ marginTop: '-1%' }}>
                    <Typography variant='subtitle1' sx={{ fontWeight: "500" }}>Membership</Typography>
                </Grid>

                <Grid item xs={12}>
                    <AppSelectList
                        control={control}
                        name={LevelOfStudyOrTraining.name}
                        label={LevelOfStudyOrTraining.label}
                        items={MapFamAffiliateCategoriesToDropdownItems(famAffiliateCategories?.filter(x => x.userCanChoose === true) ?? [])}
                    />
                </Grid>

                <Grid item xs={12}>
                    <AppSelectList
                        control={control}
                        name={StatusAffiliateMemberPathway.name}
                        label={StatusAffiliateMemberPathway.label}
                        html_information_text={StatusAffiliateMemberPathway.information_text}
                        items={MapFamAffiliatePathwaysToDropdownItems(famAffiliatePathways?.filter(x => x.userCanChoose === true) ?? [])}
                    />
                </Grid>

                {famAffiliatePathways?.find(x => x.id === getValues(StatusAffiliateMemberPathway.name))?.showComment === true &&
                    <Grid item xs={12}>
                        <AppTextareaInput
                            control={control}
                            name={AffiliateMemberPathwayComments.name}
                            label={getValues(StatusAffiliateMemberPathway.name) && famAffiliatePathways?.find(x => x.id ===
                                getValues(StatusAffiliateMemberPathway.name))?.commentGuidelines}
                        />
                    </Grid>
                }

                <Grid item xs={12}>
                    <AppDeclarationCheckbox
                        control={control}
                        name={HaveNotSuccessfullyPassedMrcsPartBExam.name}
                        option={declarationOption} />
                </Grid>

                <Grid item xs={12}>
                    <AppTextareaInput
                        control={control}
                        name={ReasonToBecomeAffiliateMemberOfRCSI.name}
                        label={ReasonToBecomeAffiliateMemberOfRCSI.label}
                    />
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>
    </>
}