import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store/configureStore';
import { ApiRequestStatus } from '../enums/apiRequestStatus';
import { useMsal } from '@azure/msal-react';
import { UserClaims } from '../enums/userClaims';
import { getPcsEnrolmentYearsAsync } from '../slices/pcsEnrolmentSlice';

export const useGetPcsEnrolmentYears = () => {
    const { accounts } = useMsal();

    useEffect(() => {
        if (accounts.length > 0 && accounts[0]?.idTokenClaims) {
            setPersonId(accounts[0].idTokenClaims[UserClaims.EXTENSION_PORTALCRM_PERSONID] as string);
        }
    }, [accounts]);

    const [personId, setPersonId] = useState('');

    const dispatch = useAppDispatch();
    const { enrolmentYears, enrolmentYearsStatus } = useAppSelector(state => state.pcsEnrolment);

    useEffect(() => {
        if (!enrolmentYears && enrolmentYearsStatus === ApiRequestStatus.Idle) {
            dispatch(getPcsEnrolmentYearsAsync(personId))
          }
    }, [enrolmentYears, enrolmentYearsStatus, dispatch]);

    return { enrolmentYearsStatus };
};
