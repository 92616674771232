import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../api/agent";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import { WorkPostDto } from "../models/workpost/workPostDto";

interface WorkpostState {
    currentWorkDetails: WorkPostDto | null;
    workpost: WorkPostDto | null;
    workpostStatus: ApiRequestStatus;
    currentWorkDetailsStatus: ApiRequestStatus;
    saveWorkpostStatus: ApiRequestStatus;
}

const initialState: WorkpostState = {
    currentWorkDetails: null,
    workpost: null,
    workpostStatus: ApiRequestStatus.Idle,
    currentWorkDetailsStatus: ApiRequestStatus.Idle,
    saveWorkpostStatus: ApiRequestStatus.Idle
}

export const getCurrentWorkDetailsAsync = createAsyncThunk<WorkPostDto[]>(
    'workpost/getCurrentWorkDetailsAsync',
    async (_, thunkAPI) => {
        try {
            return agent.WorkPost.getWorkpostsAsync();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getWorkpostAsync = createAsyncThunk<WorkPostDto, string>(
    'workpost/getWorkpostState',
    async (workpostId, thunkAPI) => {
        try {
            return agent.WorkPost.getWorkpostByIdAsync(workpostId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const saveWorkpostAsync = createAsyncThunk<void, WorkPostDto>(
        'workpost/saveWorkpostAsync',
        async (workpostDto, thunkAPI) => {
            try {
                return agent.WorkPost.submitWorkpostAsync(workpostDto);
            } catch (error: any) {
                return thunkAPI.rejectWithValue({ error: error.data });
            }
        }
    )

export const workpostSlice = createSlice({
    name: 'workpost',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(getCurrentWorkDetailsAsync.pending, (state) => {
            state.currentWorkDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getCurrentWorkDetailsAsync.fulfilled, (state, action) => {
            state.currentWorkDetailsStatus = ApiRequestStatus.Fulfilled;
            state.currentWorkDetails = action.payload[0];
        });
        builder.addCase(getCurrentWorkDetailsAsync.rejected, (state) => {
            state.currentWorkDetailsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(getWorkpostAsync.pending, (state) => {
            state.workpostStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getWorkpostAsync.fulfilled, (state, action) => {
            state.workpostStatus = ApiRequestStatus.Fulfilled;
            state.workpost = action.payload;
        });
        builder.addCase(getWorkpostAsync.rejected, (state) => {
            state.workpostStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(saveWorkpostAsync.pending, (state) => {
            state.saveWorkpostStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(saveWorkpostAsync.fulfilled, (state, action) => {
            state.workpost = action.meta.arg;
            state.saveWorkpostStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(saveWorkpostAsync.rejected, (state) => {
            state.saveWorkpostStatus = ApiRequestStatus.Rejected;
        });
    })
})
