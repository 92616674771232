import { FormProvider, useForm } from "react-hook-form";
import { Box, Grid, Paper, Typography } from "@mui/material";
import cpdFormModel from "../../common/cpdFormModel";
import { useEffect, useRef, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { ApiRequestStatus } from "../../../../app/enums/apiRequestStatus";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useAppDispatch, useAppSelector } from "../../../../app/store/configureStore";
import { SearchCreditRecordsQuery } from "../../models/searchCreditRecordsQuery";
import { getCreditRecordsStateAsync } from "../../../../app/slices/creditsSlice";
import { getValueFromId, selectAllWrapperToDropdownList, yearList } from "../../../../app/utils/util";
import CpdDataList from "./CpdDataList";
import { CreditRecord } from "../../models/creditRecord";
import { CSVLink } from "react-csv";
import AppMultiSelectList from "../../../../app/components/AppMultiSelectList";
import AppSelectListNoHelptext from "../../../../app/components/AppSelectListNoHelptext";
import { DropdownItem } from "../../../../app/models/common/dropdownItem";
import { getReferenceDataAsync } from "../../../../app/slices/referenceDataSlice";
import { MapCategoriesToDropdownItems, MapStatusesToDropdownItems } from "../../../../app/utils/convertStaticDataToDropdownItems";
import { ReferenceData } from "../../../../app/models/staticData/referenceData";
import { useMsal } from "@azure/msal-react";
import { UserClaims } from "../../../../app/enums/userClaims";

const { formField } = cpdFormModel;

export default function ViewCpdForm() {

    const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

    const methods = useForm({
        mode: 'all',
        defaultValues: {
            [formField.Year.name]: [new Date().getFullYear().toString()]
        }
    });

    const dispatch = useAppDispatch();
    const { cpdCategories, cpdRecordStatuses, activityTypes, goodPracticeDomains, staticDataStateStatus } = useAppSelector(state => state.ReferenceData);
    const { creditRecords, cpdStateStatus } = useAppSelector(state => state.credits);

    const { accounts } = useMsal();

    useEffect(() => {
        if (accounts.length > 0 && accounts[0]?.idTokenClaims) {
            setPersonId(accounts[0].idTokenClaims[UserClaims.EXTENSION_PORTALCRM_PERSONID] as string);
        }
    }, [accounts]);

    const [personId, setPersonId] = useState('');
    const [creditRecordList, setCreditRecordList] = useState<any[]>([]);
    const [categories, setCategories] = useState<DropdownItem[]>([]);
    const [statuses, setStatuses] = useState<DropdownItem[]>([]);

    useEffect(() => {
        if (!cpdCategories && !activityTypes && !goodPracticeDomains) {
            dispatch(getReferenceDataAsync()).then((response: any) => {
                if (response.payload) {
                    let responseData = response.payload as ReferenceData;
                    setCategories(selectAllWrapperToDropdownList(MapCategoriesToDropdownItems(responseData?.cpdCategories ?? [])));
                    setStatuses(selectAllWrapperToDropdownList(MapStatusesToDropdownItems(responseData?.cpdRecordStatuses ?? [])));
                }
            });
            return;
        }

        if (cpdCategories) {
            setCategories(selectAllWrapperToDropdownList(MapCategoriesToDropdownItems(cpdCategories ?? [])));
        }

        if (cpdRecordStatuses) {
            setStatuses(selectAllWrapperToDropdownList(MapStatusesToDropdownItems(cpdRecordStatuses ?? [])));
        }
    }, [cpdCategories, activityTypes, goodPracticeDomains, cpdRecordStatuses, dispatch]);

    useEffect(() => {
        if (!cpdCategories && !cpdRecordStatuses && !activityTypes && !goodPracticeDomains) {
            dispatch(getReferenceDataAsync());
        }
    }, [cpdCategories, cpdRecordStatuses, activityTypes, goodPracticeDomains, dispatch]);

    useEffect(() => {
        if (!creditRecords) {
            const searchObj: SearchCreditRecordsQuery = {
                personId: personId,
                year: methods.getValues([formField.Year.name])[0],
                cpdCategoryID: methods.getValues([formField.Category.name]).toString(),
                cpdRecordStatusID: methods.getValues([formField.Status.name]).toString()
            };

            dispatch(getCreditRecordsStateAsync(searchObj));
        }
    }, [creditRecords, dispatch]);

    const [cpdYears,] = useState<DropdownItem[]>(yearList(new Date().getFullYear()));

    useEffect(() => {
        if (cpdCategories && cpdRecordStatuses && activityTypes && goodPracticeDomains) {
            onSearchClick();
        }
    }, [cpdCategories, cpdRecordStatuses, activityTypes, goodPracticeDomains]);

    function onSearchClick() {
        if (personId !== '') {
            let categoryId = methods.getValues([formField.Category.name]).toString();
            let statusId = methods.getValues([formField.Status.name]).toString();

            const searchObj: SearchCreditRecordsQuery = {
                personId: personId,
                year: methods.getValues([formField.Year.name])[0],
                cpdCategoryID: categoryId === "0" ? "" : categoryId,
                cpdRecordStatusID: statusId === "0" ? "" : statusId
            };

            dispatch(getCreditRecordsStateAsync(searchObj)).then((response: any) => {
                setCreditRecordList(formatCreditRecords(response?.payload));
            });
        }
    }

    function formatCreditRecords(response: any) {
        const creditRecords: CreditRecord[] = [];
        if (response && response.length && response.length > 0) {
            response?.map((item: CreditRecord) => {
                const credirRecord: CreditRecord = { ...item };
                credirRecord.cpdCategory = getValueFromId(cpdCategories, item.cpdCategoryId);
                credirRecord.activityType = getValueFromId(activityTypes, item.activityTypeId);
                credirRecord.cpdRecordStatus = getValueFromId(cpdRecordStatuses, item.cpdRecordStatusId);

                creditRecords.push(credirRecord);
                return item;
            });
        }
        return creditRecords;
    }

    function formatCreditRecordList(data: any) {
        return data.map(({ creditRecordId, personId, activityDate, goodPracticeDomainIds,
            credits, description, purpose, cpdCategory, activityType, cpdRecordStatus }: any) =>
        ({
            creditRecordId, personId, activityDate, goodPracticeDomainIds,
            credits, description, purpose, cpdCategory, activityType, cpdRecordStatus
        }))
    }

    function isPageProcessing() {
        return (cpdStateStatus === ApiRequestStatus.Pending || staticDataStateStatus === ApiRequestStatus.Pending);
    }

    const headers = [
        {
            label: "Date of activity",
            key: "activityDate"
        },
        {
            label: "Category",
            key: "cpdCategory"
        },
        {
            label: "Activity type",
            key: "activityType"
        },
        {
            label: "Activity description",
            key: "description"
        },
        {
            label: "Credit",
            key: "credits"
        },
        {
            label: "Status",
            key: "cpdRecordStatus"
        },
        {
            label: "No. of Documents attached",
            key: ""
        }
    ];

    return <>
        {isPageProcessing() && <LoadingComponent message="Loading CPD records.." />}

        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <FormProvider {...methods}>

                <Typography variant='h4'>Search CPD Records</Typography>

                <Grid container spacing={2}>

                    <Grid item xs={12}></Grid>

                    <Grid item xs={3}>
                        <AppMultiSelectList
                            control={methods.control}
                            name={formField.Year.name}
                            label={formField.Year.label}
                            items={cpdYears} />
                    </Grid>

                    <Grid item xs={3}>
                        <AppSelectListNoHelptext
                            control={methods.control}
                            name={formField.CategoryFilter.name}
                            label={formField.CategoryFilter.label}
                            items={categories} />
                    </Grid>

                    <Grid item xs={3}>
                        <AppSelectListNoHelptext
                            control={methods.control}
                            name={formField.Status.name}
                            label={formField.Status.label}
                            items={statuses} />
                    </Grid>

                    <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'left' }}>
                        <LoadingButton variant="contained" sx={{ m: "8px" }} color='error'
                            loading={isPageProcessing()} onClick={onSearchClick}>
                            Search
                        </LoadingButton>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>

                    <Grid item xs={12}></Grid>

                    <Grid item xs={12}>
                        <CpdDataList creditRecordList={creditRecordList} categories={cpdCategories}
                            statuses={cpdRecordStatuses} activityTypes={activityTypes} />
                    </Grid>
                </Grid>

                <Grid container spacing={2}>

                    <Grid item xs={12}></Grid>

                    <Grid item xs={7}></Grid>

                    <Grid item xs={5}>
                        <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                            <LoadingButton variant="contained" loading={isPageProcessing()}
                                onClick={() => { csvLinkRef?.current?.link.click() }}
                                color='error'>
                                Download
                            </LoadingButton>
                            <CSVLink data={formatCreditRecordList(creditRecordList)} headers={headers}
                                filename={`CPD Records`} ref={csvLinkRef} hidden={true}>Download</CSVLink>
                        </Box>
                    </Grid>
                </Grid>
            </FormProvider>
        </Paper>
    </>
}
