import * as yup from 'yup';
import registrationFormModel from './registrationFormModel';
import { MESSAGE_NOT_A_VALUE } from '../../utils/constant';

const phoneRegExp = /^(?:\d{8}(?:\d{2}(?:\d{2})?)?|\(\+?\d{2,3}\)\s?(?:\d{4}[\s*.-]?\d{4}|\d{3}[\s*.-]?\d{3}|\d{2}([\s*.-]?)\d{2}\1\d{2}(?:\1\d{2})?))$/;
const cardNumberRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;

const {
  registrationDetailsFormField: {
    IMCNumber,
    RegistrationType,
    Scheme,
    EngagedInPracticeOfMedicine,
    UserConfirmation,
    Speciality
  },
  personalDetailsFormField: {
    Title,
    FirstName,
    LastName,
    DOB,
    Gender,
    Nationality,
    MobileNumber
  },
  educationalDetailsFormField: {
    QualificationCountry,
    PrimaryMedicalDegreeCollege,
    OtherPrimaryMedicalDegreeCollege,
    QualificationYear
  },
  workDetailsFormField: {
    IfEngagedInPracticeOfMedicine
  },
  paymentDetailsFormField: {
    NameOnCard,
    CardNumber,
    ExpiryDate,
    CVV
  }
} = registrationFormModel;

export const registrationValidationSchema = [
  yup.object().shape({
    [IMCNumber.name]: yup.number().required(`${IMCNumber.requiredErrorMsg}`).typeError(`${IMCNumber.typeError}`),
    [RegistrationType.name]: yup.string().required(`${RegistrationType.requiredErrorMsg}`),
    [Scheme.name]: yup.string().required(`${Scheme.requiredErrorMsg}`),
    [EngagedInPracticeOfMedicine.name]: yup.string().required(`${EngagedInPracticeOfMedicine.requiredErrorMsg}`),
    [Speciality.name]: yup.string().required(`${Speciality.requiredErrorMsg}`),
    [UserConfirmation.name]: yup.array().min(1, UserConfirmation.requiredErrorMsg).of(yup.string().required(UserConfirmation.requiredErrorMsg))
  }),
  yup.object({
    [Title.name]: yup.string().required(`${Title.requiredErrorMsg}`),
    [FirstName.name]: yup.string().required(`${FirstName.requiredErrorMsg}`),
    [LastName.name]: yup.string().required(`${LastName.requiredErrorMsg}`),
    [DOB.name]: yup.string().nullable().required(`${DOB.requiredErrorMsg}`),
    [Gender.name]: yup.string().required(`${Gender.requiredErrorMsg}`),
    [Nationality.name]: yup.string().required(`${Nationality.requiredErrorMsg}`),
    [MobileNumber.name]: yup.string().required(`${MobileNumber.requiredErrorMsg}`)
      .matches(phoneRegExp, `${MobileNumber.typeError}`)
  }),
  yup.object({
    [QualificationCountry.name]: yup.string().required(`${QualificationCountry.requiredErrorMsg}`),
    [PrimaryMedicalDegreeCollege.name]: yup.string().when([OtherPrimaryMedicalDegreeCollege.name], ([otherPrimaryMedicalDegreeCollege], schema) => {
      return schema.test(`${PrimaryMedicalDegreeCollege.name}`, `${PrimaryMedicalDegreeCollege.requiredErrorMsg}`, function (primaryMedicalDegreeCollege) {
        if (primaryMedicalDegreeCollege === MESSAGE_NOT_A_VALUE) {
          return true;
        }
        if (otherPrimaryMedicalDegreeCollege !== "" || primaryMedicalDegreeCollege !== "") {
          return true;
        }
        return false;
      });
    }),
    [QualificationYear.name]: yup.string().required(`${QualificationYear.requiredErrorMsg}`)
  }),
  yup.object({
    [IfEngagedInPracticeOfMedicine.name]: yup.array().min(1).of(yup.string().required(IfEngagedInPracticeOfMedicine.requiredErrorMsg))
      .required(IfEngagedInPracticeOfMedicine.requiredErrorMsg),
  }),
  yup.object({
    [NameOnCard.name]: yup.string().required(`${NameOnCard.requiredErrorMsg}`),
    [CardNumber.name]: yup.string()
      .required(`${CardNumber.requiredErrorMsg}`)
      .matches(cardNumberRegEx, CardNumber.invalidErrorMsg),
    [ExpiryDate.name]: yup.string().required(`${ExpiryDate.requiredErrorMsg}`),
    [CVV.name]: yup.string()
      .required(`${CVV.requiredErrorMsg}`)
      .test('len', `${CVV.invalidErrorMsg}`, (val: any) => val && val.length === 3)
  })
]
